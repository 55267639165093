export const bgData = [
  {
    id: 1,
    name: "a",
    url:
      "https://ik.imagekit.io/imgkitt/Wedding_Bg/pexels-zulian-yuliansyah-573130.jpg?updatedAt=1687167694452",
  },
  {
    id: 2,
    name: "b",
    url:
      "https://ik.imagekit.io/imgkitt/Wedding_Bg/pexels-photomix-company-96627.jpg?updatedAt=1687112436609",
  },
  {
    id: 3,
    name: "c",
    url:
      "https://ik.imagekit.io/imgkitt/Wedding_Bg/pexels-pixabay-262713.jpg?updatedAt=1687145231651",
  },
  {
    id: 4,
    name: "d",
    url:
      "https://ik.imagekit.io/imgkitt/Wedding_Bg/pexels-asad-photo-maldives-169193.jpg?updatedAt=1687108240187",
  },
];
