import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { BiMessage } from 'react-icons/bi';
import axios from "axios"

const ChatBot = () => {
    const [isIframeOpen, setIsIframeOpen] = useState(false);
    const [getChatbotLink, setgetChatbotLink] = useState();

    const getChatbot = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/chat/fetchChat`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Headers": "X-Requested-With",
                },
            })
        response.data.map((item) => setgetChatbotLink(item.link))
    }

    useEffect(() => { getChatbot() }, []);

    const handleFrame = () => {
        if (window.matchMedia("(min-width: 768px)").matches) {
            setIsIframeOpen(!isIframeOpen);
        } else {
            window.location.href = getChatbotLink
        }
    }
    return (
        <div>
            <div className="chatbot-icon" onClick={handleFrame}>
                <BiMessage className="text-[25px]" />
            </div>
            {isIframeOpen && (
                <iframe
                    src={getChatbotLink}
                    width="400"
                    height="550"
                    title="Embedded Content"
                    style={{ display: 'block', zIndex: 999, position: 'fixed', right: '20px', bottom: '100px' }}
                ></iframe>
            )}
        </div>
    )
}

export default ChatBot